import React, { useEffect, useState, useRef, useContext } from 'react';
import ReactQuill , { Quill } from 'react-quill';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import 'react-quill/dist/quill.snow.css';
import {Header, Nav} from '../widgets/Header';
import ToastNotification from '../components/ToastNotification';
import axios from 'axios';
import io from 'socket.io-client';
import { useSocket } from '../context/SocketContext';

export function Whatsapp() {
    const { socket, connected } = useSocket();
    const [mensaje, setMensaje] = useState(''); // Estado para manejar el contenido del mensaje
    const [mensajeConvertido, setMensajeConvertido] = useState(''); // Estado para manejar el contenido del mensaje
    const [mensajeEstado, setMensajeEstado] = useState('');
    const [plantillas, setPlantillas] = useState([]);
    const quillRef = useRef(null);
    const [plantillaSeleccionada, setPlantillaSeleccionada] = useState(null);
    const [nuevoContenido, setNuevoContenido] = useState('');
    const [showModal, setShowModal] = useState(false);


    const [archivo, setArchivo] = useState(null);
    const [columnasFaltantes, setColumnasFaltantes] = useState([]);
    const [lotes, setLotes] = useState([]);
    const [numeroContactos, setNumeroContactos] = useState(0);
    const [tiemposPorLote, setTiemposPorLote] = useState([]);
    const [tiempoTotalEstimado, setTiempoTotalEstimado] = useState('');

    const [qrCode, setQrCode] = useState('');
    const [estadoWhatsApp, setEstadoWhatsApp] = useState('Conectando...');
    const [progresoLotes, setProgresoLotes] = useState([]);
    const [envioEnProceso, setEnvioEnProceso] = useState(false);

    const camposRequeridos = ['nombre', 'telefono', 'agente', 'saldo'];

    const obtenerVariablesDePlantilla = (plantilla) => {
        const regex = /\{(.*?)\}/g;
        const variables = [];
        let match;
        while ((match = regex.exec(plantilla)) !== null) {
            variables.push(match[1]);
        }
        return variables;
    };

    useEffect(() => {
        const socket = io('https://system-cammart-production.up.railway.app');

        // Recibir el estado de conexión del cliente de WhatsApp
        socket.on('estado', (estado) => {
            setEstadoWhatsApp(estado);
        });

        // Recibir el QR desde el servidor
        socket.on('qr', (qr) => {
            setQrCode(qr);  // Almacena el código QR para mostrarlo
        });
    
        // Al montar el componente, consulta el progreso actual del envío
        axios.get('https://system-cammart-production.up.railway.app/progreso-envio')
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setProgresoLotes(res.data);
                }
            })
            .catch(error => {
                console.error('Error al consultar el progreso del envío', error);
            });
    
        // Recibir el progreso de los lotes en tiempo real
        socket.on('progreso_lote', (data) => {
            setProgresoLotes((prevProgresos) => {
                const actualizados = [...prevProgresos];
                const indexLote = actualizados.findIndex((l) => l.lote === data.lote);
    
                if (indexLote !== -1) {
                    actualizados[indexLote].progreso = data.progreso;
                    actualizados[indexLote].estado = data.estado;
                    actualizados[indexLote].mensajeActual = data.mensajeActual;
                } else {
                    actualizados.push({
                        lote: data.lote,
                        progreso: data.progreso,
                        estado: data.estado,
                        mensajeActual: data.mensajeActual,
                    });
                }
    
                return actualizados;
            });
    
            if (data.estado === 'completado' || data.estado === 'cancelado') {
                setEnvioEnProceso(false);
            }
        });
    
        return () => socket.disconnect();  // Asegura que el socket se desconecte al salir de la página
    }, []);
    

    const detenerEnvio = () => {
        // Aquí puedes emitir el evento al backend para cancelar el envío
        socket.emit('detener_envio');
        setEnvioEnProceso(false); // Actualiza el estado para reflejar que se ha detenido el envío
    };

    const cancelarEnvio = async () => {
        try {
            await axios.post('https://system-cammart-production.up.railway.app/cancelar-envio');
            setEnvioEnProceso(false);
            } catch (error) {
            console.error('Error al cancelar el envío:', error);
        }
    };

    // Función para desconectar WhatsApp
    const desconectarWhatsApp = async () => {
        try {
            const response = await axios.post('https://system-cammart-production.up.railway.app/desconectar-whatsapp');
            if (response.status === 200) {
                alert('Cliente de WhatsApp desconectado exitosamente.');
            }
        } catch (error) {
            alert('Error al desconectar el cliente de WhatsApp.');
        }
    };

    // Función para reiniciar WhatsApp
    const reiniciarWhatsApp = async () => {
        try {
            const response = await axios.post('https://system-cammart-production.up.railway.app/reiniciar-whatsapp');
            if (response.status === 200) {
                alert('Cliente de WhatsApp reiniciado exitosamente. Escanea el nuevo código QR.');
            }
        } catch (error) {
            alert('Error al reiniciar el cliente de WhatsApp.');
        }
    };

    // Función para procesar archivo CSV o Excel
    const manejarArchivo = (e) => {
        const file = e.target.files[0];
        setArchivo(file);

        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);

            if (file.name.endsWith('.csv')) {
                const csvString = new TextDecoder().decode(data);
                const csvRows = csvString.split('\n').map(row => row.split(','));
                procesarDatos(csvRows);
            } else {
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                procesarDatos(jsonData);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    // Procesar los datos del archivo y preparar los lotes
    const procesarDatos = (jsonData) => {
        if (jsonData.length > 0) {
            const headers = jsonData[0].map(header => header.toLowerCase());
            const contactos = jsonData.slice(1).map(row => {
                const contacto = {};
                headers.forEach((header, index) => {
                    contacto[header] = row[index];
                });
                return contacto;
            });

            setNumeroContactos(contactos.length);
            calcularLotes(contactos);
        }
    };

    // Calcular los lotes y asignar tiempos y plantillas
    const calcularLotes = (contactos) => {
        const minLote = 5;
        const maxLote = 10;
        let registrosRestantes = contactos.length;
        const tiemposLotes = [];
        let tiempoTotal = 0;
        let numeroDeLotes = 0;
        while (registrosRestantes > 0) {
            const tamanioLote = Math.floor(Math.random() * (maxLote - minLote + 1)) + minLote;
            const contactosLote = contactos.slice(contactos.length - registrosRestantes, contactos.length - registrosRestantes + tamanioLote);
            registrosRestantes -= tamanioLote;
            numeroDeLotes++;

            let tiempoLote = 0;
            const tiemposMensajes = contactosLote.map((contacto) => {
                const tiempoPorMensaje = Math.floor(Math.random() * (120 - 30 + 1)) + 30;
                const plantillaAleatoria = plantillas[Math.floor(Math.random() * plantillas.length)];
                tiempoLote += tiempoPorMensaje;

                return {
                    numero: `521${contacto.telefono}`,
                    idPlantilla: plantillaAleatoria.PLANTILLA_ID,
                    tiempoPorMensaje,
                    variables: {
                        nombre: contacto.nombre,
                        telefono: contacto.telefono,
                        agente: contacto.agente,
                        saldo: contacto.saldo,
                    }
                };
            });

            tiempoTotal += tiempoLote;

            tiemposLotes.push({
                lote: numeroDeLotes,
                cantidadNumeros: tamanioLote,
                tiempoLote,
                tiemposMensajes
            });
        }

        setLotes(numeroDeLotes);
        setTiemposPorLote(tiemposLotes);
        setTiempoTotalEstimado(`${tiempoTotal} segundos`);
    };

    // Función para procesar el envío de mensajes
    const procesarEnvio = async () => {
        // console.log('Enviando los siguientes datos al backend:', {
        //     lotes: tiemposPorLote,
        // });

        try {
            const response = await axios.post('https://system-cammart-production.up.railway.app/procesar-envio', {
                lotes: tiemposPorLote,
            });

            if (response.status === 200) {
                alert('Mensajes procesados y enviados correctamente.');
            }
        } catch (error) {
            console.error('Error al procesar el envío:', error);
            alert('Hubo un error al procesar el envío.');
        }
    };

     // Función para manejar cambios en el editor de Quill
    const handleChange = (content) => {
        const mensajeLimpio = convertirAFormatoWhatsApp(content);
        setMensaje(content); 
        setMensajeConvertido(mensajeLimpio); 
    };
    
    // Función para insertar variable en el mensaje
    const insertarVariable = (variable) => {
        const quillEditor = quillRef.current.getEditor(); // Obtener la referencia al editor de Quill
        const selection = quillEditor.getSelection(); // Obtener la posición del cursor

        // Si no hay selección activa, fijar el cursor al final del contenido
        const cursorPosition = selection ? selection.index : quillEditor.getLength();

        quillEditor.insertText(cursorPosition, ` {${variable}} `, 'user');
    };

    // Función para convertir el HTML a formato WhatsApp
    const convertirAFormatoWhatsApp = (html) => {
        let texto = html
             .replace(/<strong>(.*?)<\/strong>/g, '*$1*') // Negritas
             .replace(/<em>(.*?)<\/em>/g, '_$1_') // Cursivas
             .replace(/<u>(.*?)<\/u>/g, '~$1~') // Subrayado
             .replace(/<\/?[^>]+(>|$)/g, "") // Remover todas las demás etiquetas HTML
             .replace(/\s+/g, ' ') // Reemplazar saltos de línea con espacios
            .trim();
        return texto;
    };

    // Obtener las plantillas del backend
    const obtenerPlantillas = async () => {
        try {
            const response = await axios.get('https://system-cammart-production.up.railway.app/obtener-plantillas');
            if (response.status === 200) {
                setPlantillas(response.data.plantillas);
            }
        } catch (error) {
            console.error('Error al obtener las plantillas:', error);
        }
    };

    // Obtener las plantillas cuando se monta el componente
    useEffect(() => {
        obtenerPlantillas();
    }, []);

    //Funcion para guardar plantillas
    const guardarPlantilla = async () => {
        try {
            const response = await axios.post('https://system-cammart-production.up.railway.app/guardar-plantilla', { mensajeConvertido });
            if (response.status === 200) {
                setMensajeEstado('Plantilla guardada exitosamente.');
                // Limpiar el editor después de guardar
                setMensaje('');
                setMensajeConvertido('');
                obtenerPlantillas();
            }
        } catch (error) {
            setMensajeEstado('Error al guardar la plantilla.');
        }
    };

    //Funcion para eliminar plantilla
    const handleEliminarPlantilla = async (plantillaId) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar esta Plantilla?',
            text: "Esta acción no se puede deshacer",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
            }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                const response = await axios.delete(`https://system-cammart-production.up.railway.app/eliminar-plantilla/${plantillaId}`);
                if (response.status === 200) {
                    setPlantillas((prevPlantillas) =>
                    prevPlantillas.filter((plantilla) => plantilla.PLANTILLA_ID !== plantillaId)
                    );
                    Swal.fire('¡Eliminada!', 'La plantilla ha sido eliminada.', 'success');
                }
                } catch (error) {
                console.error("Error al eliminar la plantilla:", error);
                Swal.fire('Error', 'Hubo un problema al eliminar la plantilla.', 'error');
                }
            }
        });
    };

    const handleEditarPlantilla = (plantilla) => {
        setPlantillaSeleccionada(plantilla);
        setNuevoContenido(plantilla.PLANTILLA_CONTENIDO); // Cargar el contenido actual
        setShowModal(true); // Abrir el modal
        
    };

    const guardarCambios = async () => {
        if (plantillaSeleccionada) {
            try {
                // Envía los cambios al backend
                const response = await axios.post('https://system-cammart-production.up.railway.app/editar-plantilla', {
                    id: plantillaSeleccionada.PLANTILLA_ID,
                    contenido: nuevoContenido
                });
    
                if (response.status === 200) {
                    // Si la actualización fue exitosa, cierra el modal
                    setShowModal(false);
                    alert('Plantilla actualizada exitosamente.');
                    obtenerPlantillas();
                    // Aquí podrías refrescar la lista de plantillas o hacer cualquier acción necesaria
                } else {
                    alert('Hubo un problema actualizando la plantilla.');
                }
            } catch (error) {
                console.error('Error actualizando la plantilla:', error);
                alert('Hubo un error al intentar actualizar la plantilla.');
            }
        }
    };
    
    

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Header />
                <div className="layout-page">
                <Nav />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">

                            {/* Mostrar estado del guardado */}
                            {mensajeEstado && <ToastNotification message={mensajeEstado} />}

                            <div className="row">
                                <div className="col-md-12" style={{marginBottom:'20px'}}>
                                    <div className="card">
                                        <h5 className="card-header"><i className="ri-whatsapp-line" style={{fontSize:'30px', color:'#c4943c' }}></i> WhatsApp API</h5>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-header d-flex align-items-center justify-content-between">
                                            <div className="card-title mb-0" style={{ textAlign: 'left' }}>
                                                <h5 className="m-0 me-2">Panel de control</h5>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                        <div className="nav-align-top">
                                            <ul className="nav nav-tabs nav-fill" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button type="button" className="nav-link waves-effect active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-new" aria-controls="navs-justified-new" aria-selected="true" tabindex="-1">
                                                Proceso
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button type="button" className="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-link-preparing" aria-controls="navs-justified-link-preparing" aria-selected="false" tabindex="-1">
                                                Importar Datos
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button type="button" className="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-link-shipping" aria-controls="navs-justified-link-shipping" aria-selected="false">
                                                Plantillas
                                                </button>
                                            </li>
                                            </ul>
                                            <div className="tab-content border-0 pb-20 px-6 mx-1">
                                                <div className="tab-pane fade active show" id="navs-justified-new" role="tabpanel">
                                                    <h4>Progreso de Envíos</h4>
                                                    {progresoLotes.map((lote) => (
                                                        <div key={lote.lote} className="my-3">
                                                            <h5><i className="ri-box-3-fill"></i> Lote {lote.lote}</h5>
                                                            <div className="d-flex" style={{flexDirection:'column'}}>
                                                                <div style={{ width: '70%', marginRight: '10px' }}>
                                                                    <div className="progress" style={{height: '16px'}}>
                                                                        <div
                                                                            className="progress-bar progress-bar-striped progress-bar-animated bg-primary"
                                                                            role="progressbar"
                                                                            style={{ width: `${lote.progreso}%` }}
                                                                            aria-valuenow={lote.progreso}
                                                                            aria-valuemin="0"
                                                                            aria-valuemax="100"
                                                                        >
                                                                            {lote.progreso}%
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {lote.estado === 'enviando' && (
                                                                    <div>
                                                                        <div className="spinner-border spinner-border-sm text-primary" role="status">
                                                                            <span className="visually-hidden">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {lote.estado === 'completado' && (
                                                                    <span className="badge badge-center rounded-pill bg-label-success">
                                                                        <i className="ri-check-line"></i> Completado
                                                                    </span>
                                                                )}
                                                                <p>{lote.mensajeActual}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="tab-pane fade" id="navs-justified-link-preparing" role="tabpanel">
                                                    <div className="row">
                                                        {/* Columna izquierda: Importar archivo y resultados */}
                                                        <div className="col-md-8">
                                                            <h4>Importar archivo para envío masivo</h4>

                                                            <div className="input-group" style={{marginBottom:'20px'}}>
                                                                <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload" onChange={manejarArchivo} />
                                                            </div>

                                                            {/* Mostrar errores si faltan columnas */}
                                                            {columnasFaltantes.length > 0 && (
                                                                <div className="alert alert-danger">
                                                                    <strong>Error:</strong> Faltan columnas en el archivo: {columnasFaltantes.join(', ')}
                                                                </div>
                                                            )}

                                                            {/* Mostrar el número de lotes y tiempos si se calcularon correctamente */}
                                                            {lotes > 0 && (
                                                                <div>
                                                                    <h5>Resultados del cálculo</h5>
                                                                    <p><i className="ri-whatsapp-fill" style={{color:'#041c34'}}></i><strong>Total de contactos:</strong> {numeroContactos}</p>
                                                                    <p><i className="ri-box-3-fill" style={{color:'#041c34'}}></i><strong>Número de lotes:</strong> {lotes}</p>
                                                                    <p><i className="ri-timer-fill" style={{color:'#041c34'}}></i><strong>Tiempo total estimado:</strong> {tiempoTotalEstimado}</p>
                                                                </div>
                                                            )}

                                                            {/* Mostrar el estado de la operación */}
                                                            <button className="btn btn-primary" onClick={procesarEnvio}>
                                                                Procesar Envío
                                                            </button>

                                                            {mensajeEstado && (
                                                                <div className="alert alert-info">
                                                                    {mensajeEstado}
                                                                </div>
                                                            )}
                                                        </div>

                                                        {/* Columna derecha: Detalles de los lotes y QR */}
                                                        <div className="col-md-4">
                                                            {lotes > 0 && (
                                                                <div>
                                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '10px' }}>
                                                                        <button 
                                                                            className="btn btn-primary" 
                                                                            data-bs-toggle="modal" 
                                                                            data-bs-target="#modalScrollable"
                                                                        >
                                                                            Ver Detalles de Lotes
                                                                        </button>
                                                                    </div>

                                                                    {/* Modal */}
                                                                    <div className="modal fade modal-tall" id="modalScrollable" tabIndex="-1" aria-hidden="true">
                                                                        <div className="modal-dialog modal-dialog-scrollable" role="document">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h4 className="modal-title" id="modalScrollableTitle">Detalles de Lotes</h4>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    {tiemposPorLote.map((lote, index) => (
                                                                                        <div key={index}>
                                                                                            <p><i className="ri-box-3-fill" style={{color:'#c4943c'}}></i> <strong>Lote {lote.lote}:</strong> {lote.cantidadNumeros} números, <i className="ri-timer-fill" style={{color:'#c4943c'}}></i> {lote.tiempoLote} segundos</p>
                                                                                            <ul>
                                                                                                {lote.tiemposMensajes.map((mensaje, i) => (
                                                                                                    <li key={i}>
                                                                                                        Mensaje {i + 1}: {mensaje.tiempoPorMensaje} segundos
                                                                                                    </li>
                                                                                                ))}
                                                                                            </ul>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )}

                                                            {qrCode && (
                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '10px' }}>
                                                                    <p style={{ marginBottom: '0px' }}>Escanea este QR con tu WhatsApp:</p>
                                                                    <img src={qrCode} alt="Código QR para WhatsApp" style={{ marginTop: '10px' }} />
                                                                </div>
                                                            )}

                                                            <div style={{ marginTop: '10px' }}>
                                                                {estadoWhatsApp === 'Cliente de WhatsApp conectado' ? (
                                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                                                                        <span className="badge rounded-pill bg-label-success">Cliente conectado para enviar WhatsApp</span>
                                                                        <button className="btn btn-outline-danger waves-effect" style={{ marginLeft: '10px', marginTop:'10px' }} onClick={desconectarWhatsApp}>
                                                                            Desconectar
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                                                                    <span className="badge rounded-pill bg-label-danger">Cliente no conectado</span>
                                                                    <button className="btn btn-primary" style={{ marginTop: '10px' }} onClick={reiniciarWhatsApp}>
                                                                        Reiniciar WhatsApp
                                                                    </button>
                                                                </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                            
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade " id="navs-justified-link-shipping" role="tabpanel">
                                                    <div className=" mb-6">
                                                        <div className="card-header">
                                                            <h5 className="card-tile mb-0">Personalizacion de plantilla</h5>
                                                        </div>

                                                        <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-control p-0 pt-1">
                                                                <div className="comment-toolbar border-0 border-bottom ql-toolbar">
                                                                    <div className="d-flex justify-content-start flex-wrap" style={{ gap: '10px', marginBottom: '10px', marginLeft: '10px' }}>
                                                                        <span className="badge rounded-pill bg-label-dark" style={{ cursor: 'pointer' }} onClick={() => insertarVariable('nombre')}>nombre</span>
                                                                        <span className="badge rounded-pill bg-label-dark" style={{ cursor: 'pointer' }} onClick={() => insertarVariable('telefono')}>telefono</span>
                                                                        <span className="badge rounded-pill bg-label-dark" style={{ cursor: 'pointer' }} onClick={() => insertarVariable('agente')}>agente</span>
                                                                        <span className="badge rounded-pill bg-label-dark" style={{ cursor: 'pointer' }} onClick={() => insertarVariable('saldo')}>saldo</span>
                                                                    </div>

                                                                    {/* Editor de Quill */}
                                                                    <ReactQuill
                                                                        ref={quillRef}
                                                                        value={mensaje}
                                                                        onChange={handleChange}
                                                                        modules={{
                                                                            toolbar: [
                                                                                ['bold', 'italic', 'underline'], // Opciones básicas de formato
                                                                                [{ 'emoji': true }] // Emoji support
                                                                            ],
                                                                        }}
                                                                        formats={['bold', 'italic', 'underline']}
                                                                        placeholder="Escribe tu mensaje aquí..."
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* Previsualización */}
                                                            <div className="mb-6">
                                                                <div className="card-body" style={{ paddingLeft: '0px', paddingTop: '10px' }}>
                                                                    <div id="bubble-editor" className="ql-container ql-bubble">
                                                                        <div className="ql-editor" data-gramm="false" >
                                                                            <h6 className="card-header" style={{ fontWeight: '700' }}>Previsualización</h6>
                                                                            <p>{mensajeConvertido}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* Botón para guardar la plantilla */}
                                                            <button className="btn btn-primary" onClick={guardarPlantilla}>Guardar Plantilla</button>
                                                        </div>

                                                        {/* Modal de edición */}
                                                        {showModal && (
                                                            <div className="modal fade show" id="modalCenter" tabindex="-1" aria-modal="true" role="dialog" style={{ display: 'block' }}>
                                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                    <h4 className="modal-title" id="modalCenterTitle">Editar Plantilla</h4>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                    <div className="row">
                                                                        <div className="col mb-6 mt-2">
                                                                            <div className="form-floating form-floating-outline mb-6">
                                                                                <textarea className="form-control h-px-100" id="exampleFormControlTextarea1" value={nuevoContenido} onChange={(e) => setNuevoContenido(e.target.value)}></textarea>
                                                                                <label for="exampleFormControlTextarea1">Contenido</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cerrar</button>
                                                                        <button type="button" className="btn btn-primary" onClick={guardarCambios}>Guardar Cambios</button>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        )}



                                                            <div className="col-md-6">
                                                                <div className="card">
                                                                    <div className="table-responsive">
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr className="text-nowrap">
                                                                                <th>#</th>
                                                                                <th>Plantilla</th>
                                                                                <th>Estatus</th>
                                                                                <th>Acción</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="table-border-bottom-0">
                                                                                {plantillas.length > 0 ? (
                                                                                plantillas.map((plantilla, index) => (
                                                                                    <tr key={plantilla.PLANTILLA_ID}>
                                                                                        <th scope="row">{index + 1}</th>
                                                                                        <td>{plantilla.PLANTILLA_CONTENIDO}</td>
                                                                                        <td>
                                                                                            <span className="badge rounded-pill bg-label-success me-1">Activo</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            {/* Botón de eliminar */}
                                                                                            <button
                                                                                                className="btn btn-outline-danger me-2"
                                                                                                onClick={() => handleEliminarPlantilla(plantilla.PLANTILLA_ID)}
                                                                                            >
                                                                                                <i className="ri-delete-bin-line"></i> {/* Remixicon del bote de basura */}
                                                                                            </button>
                                                                                            {/* Botón de editar */}
                                                                                            <button
                                                                                                className="btn btn-outline-primary"
                                                                                                onClick={() => handleEditarPlantilla(plantilla)}
                                                                                            >
                                                                                                <i className="ri-edit-2-line"></i> {/* Remixicon del lápiz de edición */}
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                                ) : (
                                                                                <tr>
                                                                                    <td colSpan="4">No hay plantillas disponibles</td>
                                                                                </tr>
                                                                                )}
                                                                            </tbody>

                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function Modulos() {
    return (
        <div>Modulos</div>
    )
}

export default Modulos