import React, { useEffect, useState } from 'react';
import {Header, Nav, Footer} from '../widgets/Header'
import { PagosSemanalChart, PagosPorSemana } from '../components/Charts';
import axios from 'axios';

export function Home() {
  const [cuentasActivas, setCuentasActivas] = useState(0);
    const [gestionesMensuales, setGestionesMensuales] = useState(0);
    const [gestionesDiarias, setGestionesDiarias] = useState(0);
    const [cuentasNegociacion, setCuentasNegociacion] = useState(0);
    const [totalPagos, setTotalPagos] = useState(0);

    useEffect(() => {
        // Realizar la única petición a la API
        const fetchData = async () => {
            try {
                const response = await axios.get('https://system-cammart-production.up.railway.app/home');
                const data = response.data;

                // Actualizar los estados con los datos obtenidos del backend
                setCuentasActivas(data.cuentas_activas || 0);
                setGestionesMensuales(data.gestiones_mensuales || 0);
                setGestionesDiarias(data.gestiones_diarias || 0);
                setCuentasNegociacion(data.cuentas_negociacion || 0);
                setTotalPagos(data.total_pagos || 0);
            } catch (error) {
                console.error('Error al obtener los datos del home:', error);
            }
        };

        fetchData();
    }, []);

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <Header />
        <div className="layout-page">
          <Nav />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row g-6 md-6">
              <div className="col-md-12">
                <div className="card">
                  <h5 className="card-header">Dashboard</h5>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                  <div className="card card-border-shadow-primary h-100">
                      <div className="card-body">
                          <div className="d-flex align-items-center mb-2">
                              <div className="avatar me-4">
                                  <span className="avatar-initial rounded-3 bg-label-primary"><i className="ri-account-circle-line ri-24px"></i></span>
                              </div>
                              <h4 className="mb-0">{cuentasActivas}</h4>
                          </div>
                          <h6 className="mb-0 fw-normal">Cuentas activas</h6>
                      </div>
                  </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                  <div className="card card-border-shadow-info h-100">
                      <div className="card-body">
                          <div className="d-flex align-items-center mb-2">
                              <div className="avatar me-4">
                                  <span className="avatar-initial rounded-3 bg-label-info"><i className="ri-chat-1-line ri-24px"></i></span>
                              </div>
                              <h4 className="mb-0">{gestionesMensuales}</h4>
                          </div>
                          <h6 className="mb-0 fw-normal">Cuentas gestionadas</h6>
                      </div>
                  </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                  <div className="card card-border-shadow-warning h-100">
                      <div className="card-body">
                          <div className="d-flex align-items-center mb-2">
                              <div className="avatar me-4">
                                  <span className="avatar-initial rounded-3 bg-label-warning"><i className="ri-shake-hands-line ri-24px"></i></span>
                              </div>
                              <h4 className="mb-0">{cuentasNegociacion}</h4>
                          </div>
                          <h6 className="mb-0 fw-normal">Cuentas en negociación</h6>
                      </div>
                  </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                  <div className="card card-border-shadow-success h-100">
                      <div className="card-body">
                          <div className="d-flex align-items-center mb-2">
                              <div className="avatar me-4">
                                  <span className="avatar-initial rounded-3 bg-label-success"><i className="ri-money-dollar-circle-line ri-24px"></i></span>
                              </div>
                              <h4 className="mb-0">{totalPagos}</h4>
                          </div>
                          <h6 className="mb-0 fw-normal">Pagos efectuados</h6>
                      </div>
                  </div>
              </div>

              <div className="col-xl-6 col-12 mb-6">
                <div className="card">
                  <div className="card-header header-elements">
                    <h5 className="card-title mb-0">Registros semanales</h5>
                    <div className="card-action-element ms-auto py-0">
                    </div>
                  </div>
                  <div className="card-body">
                    <PagosSemanalChart />
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-12 mb-6">
                <div className="card">
                  <div className="card-body">
                    <PagosPorSemana />
                  </div>
                </div>
              </div>

              </div>
            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default Home