import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        const newSocket = io('https://system-cammart-production.up.railway.app'); // Cambia a la URL de tu servidor
        setSocket(newSocket);

        // Manejar eventos de conexión y desconexión
        newSocket.on('connect', () => {
            //console.log('Conectado al WebSocket');
            setConnected(true);
        });

        newSocket.on('disconnect', () => {
            //console.log('Desconectado del WebSocket');
            setConnected(false);
        });

        return () => {
            newSocket.disconnect();
        };
    }, []);

    return (
        <SocketContext.Provider value={{ socket, connected }}>
            {children}
        </SocketContext.Provider>
    );
};
