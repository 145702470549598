import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Login, Register } from './secciones/Login';
import { Header, Footer, NotFound } from './widgets/Header';
import Perfil from './secciones/Perfil';
import { Gestiones, Pagos, PanelUsuario } from './secciones/Gestion';
import { Configuraciones, ImportarDatos } from './secciones/Configuraciones';
import {Reportes, Exportar} from './secciones/Reportes';
import {Modulos, Whatsapp} from './secciones/Modulos';
import Home from './secciones/Home';
import ProtectedRoute from './components/ProtectedRoute';
import { UserProvider } from './context/UserContext';
import { SocketProvider } from './context/SocketContext';

function App() {
  return (
    <UserProvider>
      <SocketProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            {/* Rutas protegidas */}
            <Route element={<ProtectedRoute />}>
                <Route path='/' element={<Home />} />
                <Route path='/perfil' element={<Perfil />} />
                {/* <Route path='/panel/usuario' element={<PanelUsuario />} /> */}
                <Route path='/gestion' element={<Gestiones />} />
                <Route path='/gestion/:cliente_unico' element={<Gestiones />} />
                <Route path='/pagos' element={<Pagos />} />
                <Route path="/pagos/:cliente_unico" element={<Pagos />} />
                <Route path="/whatsapp" element={<Whatsapp />} />
                <Route path='/configuracion/importar' element={<ImportarDatos />} />
                <Route path='/reportes/exportar' element={<Exportar />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </SocketProvider>
    </UserProvider>
  );
}

export default App;
