import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute = () => {
    const location = useLocation();
    const [auth, setAuth] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const res = await axios.get('https://system-cammart-production.up.railway.app/auth-check', { withCredentials: true });
                //console.log('Respuesta del servidor:', res.data);
                if (res.data.Status === 'Exito') {
                    setAuth(true);
                } else {
                    setAuth(false);
                }
                setLoading(false);
            } catch (error) {
                setAuth(false);
                setLoading(false);
            }
        };

        // Verificar autenticación solo cuando se navega a una nueva ruta
        checkAuth();
    }, [location.pathname]);

    if (loading) {
        // Mostrar un loader mientras se verifica la autenticación
        return (
            <div className='col'>
                <div className="sk-chase sk-primary">
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                </div>
            </div>
        );
    }

    if (!auth) {
        // Si no está autenticado, redirigir al login
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // Si está autenticado, permitir acceso a las rutas protegidas
    return <Outlet />;
};

export default ProtectedRoute;
