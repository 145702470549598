import React, { useState, useEffect } from 'react';

const ToastNotification = ({ message, title = 'Notificación', duration = 3000 }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        // Ocultar el toast después del tiempo de duración especificado
        const timer = setTimeout(() => {
            setVisible(false); // Cambiar la visibilidad a false después de 3 segundos
        }, duration);

        return () => clearTimeout(timer); // Limpiar el timeout cuando el componente se desmonte
    }, [duration]);

    return (
        <>
        {visible && (
            <div
                className="bs-toast toast fade show"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 9999 }}
            >
            <div className="toast-header">
                <i className="ri-checkbox-circle-fill text-success me-2"></i>
                <div className="me-auto fw-medium">{title}</div>
                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div className="toast-body">{message}</div>
            </div>
        )}
        </>
    );
};

export default ToastNotification;
