import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import {Header, Nav, Footer} from '../widgets/Header'

export function Configuraciones() {
    return (
        <div>Configuraciones</div>
    )
}

export function ImportarDatos() {

    const [file, setFile] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [filasInsertadas, setFilasInsertadas] = useState(0);
    const [filasActualizadas, setFilasActualizadas] = useState(0);
    const [progress, setProgress] = useState({ insertedRows: 0, updatedRows: 0 });
    const [totalProcessed, setTotalProcessed] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const socket = io('https://system-cammart-production.up.railway.app');  // Asegúrate de que esta URL es correcta

        socket.on('progress', (data) => {
            setProgress(data || { insertedRows: 0, updatedRows: 0 }); // Aseguramos que siempre haya un valor por defecto

            const total = data.insertedRows + data.updatedRows;
            setTotalProcessed(total)
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);  // Guardar el archivo seleccionado
    };

    const handleImport = async () => {
        if (!file) {
            setErrorMessage('Por favor, selecciona un archivo.');
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append('file', file);  // Adjuntar el archivo XLSX al FormData

        try {
            const response = await axios.post('https://system-cammart-production.up.railway.app/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',  // Indicar que es una carga de archivo
                },
            });
            setFilasInsertadas(response.data.filas_insertadas);
            setFilasActualizadas(response.data.filas_actualizadas);
            setSuccessMessage('Datos importados con éxito');
            setErrorMessage('');
            setIsLoading(false);
        } catch (error) {
            setErrorMessage('Hubo un problema al importar los datos');
            setSuccessMessage('');
        }
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Header />
                <div className="layout-page">
                <Nav />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <h5 className="card-header">Importar Datos</h5>
                                        <div className="card-body">
                                            <div className="mb-4 d-flex align-items-center">
                                                <input className="form-control me-3" type="file" onChange={handleFileChange} />
                                                <button type="button" onClick={handleImport} className="btn rounded-pill btn-outline-primary waves-effect">
                                                    Importar datos
                                                </button>

                                                
                                            </div>
                                            <p style={{opacity:'0.9'}}><em>
                                                {isLoading && (
                                                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                )}
                                                Total de filas procesadas: {totalProcessed}</em>
                                            </p>

                                            {/* Mostrar el progreso de filas insertadas y actualizadas */}
                                            <div className="alert alert-primary alert-dismissible" role="alert">
                                                Filas insertadas: {progress.insertedRows} <br />
                                                Filas actualizadas: {progress.updatedRows} <br />
                                            </div>

                                            {/* Mensajes de éxito o error */}
                                            <div className="card-body" style={{ paddingTop: '0px' }}>
                                                <div className="mb-4 align-items-center">
                                                    {successMessage && (
                                                        <div className="alert alert-success alert-dismissible" role="alert">
                                                            {successMessage}
                                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                        </div>
                                                        
                                                        
                                                    )}
                                                    {errorMessage && (
                                                        <div className="alert alert-danger alert-dismissible" role="alert">
                                                            {errorMessage}
                                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Configuraciones