import React, {useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { useParams, useNavigate } from 'react-router-dom';
import {Header, Nav, Footer} from '../widgets/Header';
import ToastNotification from '../components/ToastNotification';
import axios from 'axios';

export function Gestiones() {
  const { userData, loading } = useContext(UserContext); 
  const { cliente_unico } = useParams(); // Obtener el CLIENTE_UNICO de la URL // Obtenemos el userId del contexto
  const [status, setStatus] = useState("");
  const [datosCliente, setDatosCliente] = useState({});
  const [error, setError] = useState('');
  const [correoAdicional, setCorreoAdicional] = useState('');
  const [mensajeCorreo, setMensajeCorreo] = useState('');
  const [telefonoAdicional, setTelefonoAdicional] = useState('');
  const [mensajeTelefono, setMensajeTelefono] = useState('');
  const [comentario, setComentario] = useState('');
  const [montoNegociado, setMontoNegociado] = useState('');
  const [fechaNegociada, setFechaNegociada] = useState('');
  const [mensajeGestion, setMensajeGestion] = useState('');
  const navigate = useNavigate();
  

  // Manejar cambio de estatus
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleInputChange = (e) => {
      setCorreoAdicional(e.target.value); // Almacenar el valor del input
  };


  useEffect(() => {
    if (!loading && userData) {
      //console.log(`Sesión iniciada del userId: ${userData.userid}`);
    } else if (!loading && !userData) {
      //console.log("No se pudo obtener los datos del usuario.");
    }
  }, [userData, loading]);

  // Funcion para traer todos los datos del cliente
  const obtenerDatosCliente = async () => {
    if (cliente_unico) {
      try {
        const response = await axios.get(`https://system-cammart-production.up.railway.app/asignacion/${cliente_unico}`);
        if (response.status === 200 && response.data) {
          setDatosCliente({
            ...response.data,
            correos: response.data.correos || [], 
            telefonos: response.data.telefonos || [],
            gestiones: response.data.gestiones || [],  
          });
          setError(''); 
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError('Cliente no encontrado.'); // Mensaje si no se encuentra el cliente
        } else {
          setError('Error al obtener los datos del cliente.'); // Mensaje de error general
        }
        setDatosCliente({ correos: [], telefonos: [], gestiones: [] }); // Inicializa arrays vacíos
      }
    } else {
      // Si no se pasa cliente_unico, inicializa con arrays vacíos
      setDatosCliente({ correos: [], telefonos: [], gestiones: [] });
      setError('');
    }
  };

  useEffect(() => {
    obtenerDatosCliente();
  }, [cliente_unico]);

  //Funcion para agregar un correo adicional
  const validarCorreo = (correo) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
    return regex.test(correo);
  };

  const handleEnviarCorreo = async () => {
    if (!userData || !userData.userid) {
      setMensajeCorreo('Error: No se pudo obtener el ID del usuario');
      return;
    }
  
    if (!validarCorreo(correoAdicional)) {
      setMensajeCorreo('Correo inválido. Por favor ingrese un correo electrónico válido.');
      return;
    }
  
    try {
      const response = await axios.post('https://system-cammart-production.up.railway.app/agregar-correo', {
        cliente_unico,
        correo: correoAdicional,
        userId: userData.userid,
      });
  
      if (response.status === 200) {
        setMensajeCorreo('Correo agregado exitosamente');
        setCorreoAdicional('');
  
        // Llamar la función para obtener correos actualizados
        obtenerDatosCliente();
      }
    } catch (error) {
      setMensajeCorreo('Error al agregar el correo');
    }
  };

  // Función para agregar un teléfono adicional
  const validarTelefono = (telefono) => {
    const regex = /^[0-9]{0,10}$/;
    return regex.test(telefono);
  };

  // Función para agregar un teléfono adicional
  const handleEnviarTelefono = async () => {
    if (!userData || !userData.userid) {
      setMensajeTelefono('Error: No se pudo obtener el ID del usuario');
      return;
    }

    if (!validarTelefono(telefonoAdicional)) {
      setMensajeTelefono('El número de teléfono debe tener solo números y un máximo de 10 dígitos');
      return;
    }

    try {
      const response = await axios.post('https://system-cammart-production.up.railway.app/agregar-telefono', {
        cliente_unico,
        telefono: telefonoAdicional,
        userId: userData.userid,
      });

      if (response.status === 200) {
        setMensajeTelefono('Teléfono agregado exitosamente');
        setTelefonoAdicional('');

        // Llamar la función para obtener teléfonos actualizados
        obtenerDatosCliente();
      }
    } catch (error) {
      setMensajeTelefono('Error al agregar el teléfono');
    }
  };

   // Función para enviar la gestión
    const handleEnviarGestion = async (e) => {
      e.preventDefault();

      if (!status) { // Validar si el estatus no ha sido seleccionado
        setMensajeGestion('Por favor, selecciona un estatus válido.');
        return;
      }

      if (!userData || !userData.userid) {
        setMensajeGestion('Error: No se pudo obtener el ID del usuario');
        return;
    }

    // Preparar datos para el backend
    const datosGestion = {
      id: datosCliente.cliente?.ID,
      cliente_unico,
      userId: userData.userid,
      estatus: status,
      comentario,
      monto_negociado: status === 'NEGOCIACION' ? montoNegociado : null, // Solo enviar el monto si es negociación
      fecha_negociada: status === 'NEGOCIACION' ? fechaNegociada : null, // Solo enviar la fecha si es negociación
    };

    try {
      const response = await axios.post('https://system-cammart-production.up.railway.app/agregar-gestion', datosGestion);
      if (response.status === 200) {
        setMensajeGestion('Gestión agregada exitosamente');
        setTimeout(() => setMensajeGestion(''), 3000);
        // Resetear el formulario
        setStatus('');
        setComentario('');
        setMontoNegociado('');
        setFechaNegociada('');
        obtenerDatosCliente();
      }
    } catch (error) {
      setMensajeGestion('Error al agregar la gestión');
    }
  };

  // Función para redirigir a la página de pagos
  const handleRedirectToPagos = () => {
    if (cliente_unico) {
      navigate(`/pagos/${cliente_unico}`); // Redirigir a /pagos/:cliente_unico si existe
    } else {
      navigate('/pagos'); // Redirigir a /pagos si no hay cliente_unico
    }
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <Header />
        <div className="layout-page">
          <Nav />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              {/* Mostrar alerta de error */}
              {error && (
                <div className="alert alert-danger alert-dismissible" role="alert" style={{ marginBottom: '20px' }}>
                  {error}
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
              )}

              {mensajeGestion && <ToastNotification message={mensajeGestion} />}
              {mensajeTelefono && <ToastNotification message={mensajeTelefono} />}
              {mensajeCorreo && <ToastNotification message={mensajeCorreo} />}

              <div className="row">
                <div className="card mb-6">
                  <h5 className="card-header">1. Información</h5>
                  <form className="card-body">
                    <div className="row g-6">
                      <div className="col-md-4">
                        <div>
                          <label htmlFor="smallInput" className="form-label">ID</label>
                          <input 
                            id="smallInput" 
                            className="form-control form-control-sm" 
                            type="text" 
                            value={cliente_unico || ''}
                            readOnly 
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <label htmlFor="smallInput" className="form-label">Nombre</label>
                          <input 
                            id="smallInput" 
                            className="form-control form-control-sm" 
                            type="text" 
                            value={datosCliente.cliente?.NOMBRE_CTE || ''}
                            readOnly 
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div>
                          <label htmlFor="smallInput" className="form-label">Saldo</label>
                          <input 
                            id="smallInput" 
                            className="form-control form-control-sm" 
                            type="text" 
                            value={datosCliente?.cliente ? `$ ${parseFloat(datosCliente.cliente.SALDO_TOTAL).toLocaleString('es-MX')}` : ''} // Mostrar saldo o vacío
                            readOnly 
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div>
                          <label htmlFor="smallInput" className="form-label">Dias de Atraso</label>
                          <input 
                            id="smallInput" 
                            className="form-control form-control-sm" 
                            type="text" 
                            value={datosCliente.cliente?.DIAS_ATRASO || ''}
                            readOnly 
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="row">
                <div className="card mb-6">
                  <h5 className="card-header">2. Gestión</h5>
                  <div className="card-body">
                    <div className="row g-6">
                      <div className="col-12">
                        <h6>Datos Adicionales</h6>
                        <hr className="mt-0" />
                      </div>

                      <div className="col-md-6">
                        <div className="nav-align-top mb-6">
                          <ul className="nav nav-pills mb-4" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button type="button" className="nav-link waves-effect waves-light active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-top-home" aria-controls="navs-pills-top-home" aria-selected="true">
                                Correo 
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button type="button" className="nav-link waves-effect waves-light" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-top-profile" aria-controls="navs-pills-top-profile" aria-selected="false" tabIndex="-1">
                                Telefono
                              </button>
                            </li>
                          </ul>
                          <div className="tab-content" style={{paddingLeft:'0px', paddingTop:'0px'}}>
                            <div className="tab-pane fade active show" id="navs-pills-top-home" role="tabpanel">
                              <div className="input-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Ingresa un correo adicional"
                                  value={correoAdicional}
                                  onChange={(e) => setCorreoAdicional(e.target.value)}
                                />
                                <button
                                  className="btn btn-outline-primary waves-effect"
                                  type="button"
                                  onClick={handleEnviarCorreo}
                                >
                                  Enviar
                                </button>
                              </div>
                              {/* <div className="demo-inline-spacing">
                                {mensajeCorreo && (
                                  <span className={`badge rounded-pill ${mensajeCorreo.includes('Error') ? 'bg-label-danger' : 'bg-label-success'}`}>
                                    {mensajeCorreo}
                                  </span>
                                )}
                              </div> */}
                              
                              <br />
                              <div className="table-responsive text-nowrap">
                                <table className="table">
                                  <thead>
                                    <tr className="text-nowrap">
                                      <th>Correo</th>
                                      <th>Agente</th>
                                      {/* <th>Actuacion</th> */}
                                    </tr>
                                  </thead>
                                  <tbody className="table-border-bottom-0">
                                    {/* Verifica si `correos` existe y es un array */}
                                    {Array.isArray(datosCliente.correos) && datosCliente.correos.length > 0 ? (
                                      datosCliente.correos.map((correo, index) => (
                                        <tr key={index}>
                                          <th style={{textTransform:'lowercase'}}> <a href={`mailto:${correo.MAIL_CORREO}`}>{correo.MAIL_CORREO}</a></th>
                                          <td>{correo.CORREO_USUARIO}</td>
                                          {/* <td>{correo.MAIL_ACTUACION}</td> */}
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="3">No hay correos disponibles</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="navs-pills-top-profile" role="tabpanel">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingresa un teléfono adicional"
                                  value={telefonoAdicional}
                                  onChange={(e) => setTelefonoAdicional(e.target.value)}
                                />
                                <button
                                  className="btn btn-outline-primary waves-effect"
                                  type="button"
                                  onClick={handleEnviarTelefono}
                                >
                                  Enviar
                                </button>
                              </div>

                              {/* Mensaje de éxito o error */}
                              {/* <div className="demo-inline-spacing">
                                {mensajeTelefono && (
                                  <span className={`badge rounded-pill ${mensajeTelefono.includes('Error') ? 'bg-label-danger' : 'bg-label-success'}`}>
                                    {mensajeTelefono}
                                  </span>
                                )}
                              </div> */}
                              <br />
                              <div className="table-responsive text-nowrap">
                                <table className="table">
                                  <thead>
                                    <tr className="text-nowrap">
                                      <th>Telefono</th>
                                      <th>Agente</th>
                                      {/* <th>Actuacion</th> */}
                                    </tr>
                                  </thead>
                                  <tbody className="table-border-bottom-0">
                                    {/* Verifica si hay teléfonos adicionales */}
                                    {Array.isArray(datosCliente.telefonos) && datosCliente.telefonos.length > 0 ? (
                                      datosCliente.telefonos.map((telefono, index) => (
                                        <tr key={index}>
                                          <th>{telefono.TEL_NUMERO}</th>
                                          <td>{telefono.TELEFONO_USUARIO}</td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="3">No hay teléfonos adicionales disponibles</td>
                                      </tr>
                                    )}

                                    {datosCliente.telefonosDefault ? (
                                        <>
                                          {datosCliente.telefonosDefault.TELEFONO1 && (
                                            <tr>
                                              <th>{datosCliente.telefonosDefault.TELEFONO1}</th>
                                              <td>TELEFONO 1</td>
                                            </tr>
                                          )}
                                          {datosCliente.telefonosDefault.TELEFONO2 && (
                                            <tr>
                                              <th>{datosCliente.telefonosDefault.TELEFONO2}</th>
                                              <td>TELEFONO 2</td>
                                            </tr>
                                          )}
                                          {datosCliente.telefonosDefault.TELEFONO3 && (
                                            <tr>
                                              <th>{datosCliente.telefonosDefault.TELEFONO3}</th>
                                              <td>TELEFONO 3</td>
                                            </tr>
                                          )}
                                          {datosCliente.telefonosDefault.TELEFONO4 && (
                                            <tr>
                                              <th>{datosCliente.telefonosDefault.TELEFONO4}</th>
                                              <td>TELEFONO 4</td>
                                            </tr>
                                          )}
                                        </>
                                      ) : (
                                      <tr>
                                        <td colSpan="3">No hay teléfonos predeterminados disponibles</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-md-6">
                        <form onSubmit={handleEnviarGestion}>
                        <input type="hidden" value={datosCliente.cliente?.ID} />
                        <input type="hidden" value={cliente_unico} />
                          <div className="form-floating form-floating-outline mb-6">
                            <select 
                              className="form-select" 
                              id="exampleFormControlSelect1" 
                              aria-label="Default select example"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                              placeholder="Selecciona un estatus"
                            >
                              <option value="">Selecciona un estatus</option>
                              <option value="ILOCALIZABLE">ILOCALIZABLE</option>
                              <option value="SIN CONTACTO">SIN CONTACTO</option>
                              <option value="CONTACTADO CON TERCERO">CONTACTADO CON TERCERO</option>
                              <option value="NEGOCIACION">NEGOCIACIÓN</option>
                              <option value="REGULARIZADA">REGULARIZADA</option>
                              <option value="LIQUIDADA">LIQUIDADA</option>
                              {/* Puedes agregar más estatus aquí */}
                            </select>
                            <label htmlFor="exampleFormControlSelect1">Estatus</label>
                          </div>

                          <div className="form-floating form-floating-outline mb-6">
                            <textarea 
                              className="form-control h-px-100" 
                              placeholder="Comenta aquí..."
                              value={comentario}
                              onChange={(e) => setComentario(e.target.value)}
                            ></textarea>
                            <label>Comentario</label>
                          </div>

                          {status === "NEGOCIACION" && (
                            <>
                              <div className="input-group input-group-merge mb-6">
                                <span className="input-group-text">$</span>
                                <div className="form-floating form-floating-outline">
                                  <input 
                                    type="number" 
                                    className="form-control" 
                                    placeholder="0.00"
                                    value={montoNegociado}
                                    onChange={(e) => setMontoNegociado(e.target.value)}
                                  />
                                  <label>Monto negociado</label>
                                </div>
                              </div>

                              <div className="form-floating form-floating-outline mb-6">
                                <input 
                                  className="form-control" 
                                  type="date"
                                  value={fechaNegociada}
                                  onChange={(e) => setFechaNegociada(e.target.value)}
                                />
                                <label>Fecha negociada</label>
                              </div>
                            </>
                          )}

                          <div className="col-12">
                            <button type="submit" className="btn btn-primary waves-effect waves-light" onClick={handleEnviarGestion}>
                              Enviar
                            </button>
                            <a className="btn btn-outline-primary waves-effect" style={{marginLeft:'10px'}} onClick={handleRedirectToPagos}>Ver Pagos</a>
                          </div>
                        </form>
                        {/* Mostrar mensaje */}
                        {/* {mensajeGestion && (
                          <div className="alert alert-success alert-dismissible" role="alert">
                            {mensajeGestion}
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>
                        )} */}
                      </div>

                      <div className="col-12">
                        <h6>Historico de gestiones</h6>
                        <hr className="mt-0" />
                      </div>

                      <div className='col-md-12' style={{marginTop:'0px'}}>
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Agente</th>
                                <th>Actuación</th>
                                <th>Estatus</th>
                                <th>Comentario</th>
                                <th>Monto</th>
                                <th>Fecha de negociación</th>
                              </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                              {/* Verifica si hay gestiones */}
                              {Array.isArray(datosCliente.gestiones) && datosCliente.gestiones.length > 0 ? (
                                datosCliente.gestiones.map((gestion, index) => (
                                  <tr key={index}>
                                    <td>{gestion.GESTION_USUARIO}</td> 
                                    <td>{new Date(gestion.GESTION_ACTUACION).toLocaleDateString('es-MX', {day: '2-digit',month: '2-digit',year: '2-digit'})}</td>
                                    <td>{gestion.GESTION_ESTATUS}</td> 
                                    <td>{gestion.GESTION_COMENTARIO}</td> 
                                    <td>{gestion.GESTION_MONTO_NEGOCIADO ? `$${parseFloat(gestion.GESTION_MONTO_NEGOCIADO).toLocaleString('es-MX')}` : '-'}</td>
                                    <td>{gestion.GESTION_FECHA_NEGOCIADA ? new Date(gestion.GESTION_FECHA_NEGOCIADA).toLocaleDateString('es-MX', { day: '2-digit',month: '2-digit',year: '2-digit'}): '-'}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="6">No hay gestiones disponibles</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Pagos() {

  const { cliente_unico } = useParams(); // Obtener el cliente_unico de la URL
  const { userData, loading } = useContext(UserContext); 
  const [monto, setMonto] = useState('');
  const [tipo, setTipo] = useState('');
  const [fechaPago, setFechaPago] = useState('');
  const [estatus, setEstatus] = useState('');
  const [mensajePago, setMensaje] = useState('');
  const [datosCliente, setDatosCliente] = useState({});
  const [pagos, setPagos] = useState([]);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && userData) {
      //console.log(`Sesión iniciada del userId: ${userData.userid}`);
    } else if (!loading && !userData) {
      //console.log("No se pudo obtener los datos del usuario.");
    }
  }, [userData, loading]);

  const obtenerDatosCliente = async () => {
    if (cliente_unico) {
      try {
        const response = await axios.get(`https://system-cammart-production.up.railway.app/asignacion/${cliente_unico}`);
        if (response.status === 200 && response.data) {
          setDatosCliente({
            ...response.data,
            correos: response.data.correos || [], 
            telefonos: response.data.telefonos || [],
            gestiones: response.data.gestiones || [],  
          });
          setPagos(response.data.pagos || []);
          setError(''); 
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError('Cliente no encontrado.'); // Mensaje si no se encuentra el cliente
        } else {
          setError('Error al obtener los datos del cliente.'); // Mensaje de error general
        }
        setDatosCliente({ correos: [], telefonos: [], gestiones: [] });
        setPagos([]); // Inicializa arrays vacíos
      }
    } else {
      // Si no se pasa cliente_unico, inicializa con arrays vacíos
      setDatosCliente({ correos: [], telefonos: [], gestiones: [] });
      setPagos([]);
      setError('');
    }
  };

  useEffect(() => {
    obtenerDatosCliente();
  }, [cliente_unico]);

  // Función para manejar el envío del formulario
  const handleEnviarPago = async (e) => {
    e.preventDefault();
  
    // Verificar si todos los campos necesarios están presentes
    if (!userData?.userid || !cliente_unico || !monto || !tipo || !fechaPago || !estatus) {
      setMensaje('Todos los campos son obligatorios');
      return;
    }
  
    // Preparar los datos para enviar al backend
    const datosPago = {
      id: datosCliente.cliente?.ID,  
      userId: userData.userid,       
      cliente_unico,                
      monto: parseFloat(monto),      
      tipo,                          
      fecha_pago: fechaPago,         
      estatus                        
    };
  
    try {
      const response = await axios.post('https://system-cammart-production.up.railway.app/agregar-pago', {
        id: datosCliente.cliente?.ID,
        userId: userData.userid,
        cliente_unico,
        monto,
        tipo,
        fecha_pago: fechaPago,
        estatus
      });
    
      if (response.status === 200) {
        setMensaje('Pago agregado exitosamente');
        setTimeout(() => setMensaje(''), 3000);
        // Resetear el formulario
        setMonto('');
        setTipo('');
        setFechaPago('');
        setEstatus('');
        obtenerDatosCliente();
      }
    } catch (error) {
      console.error('Error al agregar el pago:', error); // Muestra el error completo de Axios
      console.error('Detalles del error:', error.response?.data); // Muestra los datos de respuesta del servidor, si los hay
      setMensaje('Error al agregar el pago');
    }
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <Header />
        <div className="layout-page">
          <Nav />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              {/* Mostrar la alerta si hay un error */}
              {error && (
                <div className="alert alert-danger alert-dismissible" role="alert" style={{ marginBottom: '20px' }}>
                  {error}
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
              )}
              {mensajePago && <ToastNotification message={mensajePago} />}
              <div className="row">
                <div className="card mb-6">
                  <h5 className="card-header">1. Información</h5>
                  <form className="card-body">
                    <div className="row g-6">
                      <div className="col-md-6">
                        <div>
                          <label htmlFor="smallInput" className="form-label">ID</label>
                          <input 
                            id="smallInput" 
                            className="form-control form-control-sm" 
                            type="text" 
                            value={cliente_unico || ''}
                            readOnly 
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <label htmlFor="smallInput" className="form-label">Nombre</label>
                          <input 
                            id="smallInput" 
                            className="form-control form-control-sm" 
                            type="text" 
                            value={datosCliente?.cliente?.NOMBRE_CTE || ''}
                            readOnly 
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="card mb-6">
                  <h5 className="card-header">2. Gestión de pago</h5>
                  <form className="card-body" onSubmit={handleEnviarPago}>
                    <div className="row g-6">
                      <input type="hidden" value={datosCliente.cliente?.ID || ''} readOnly />
                      <input type="hidden" value={cliente_unico} readOnly />

                      <div className="col-md-3">
                        <div className="input-group input-group-merge">
                          <span className="input-group-text">$</span>
                          <div className="form-floating form-floating-outline">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="0.00"
                              aria-label="Monto"
                              value={monto}
                              onChange={(e) => setMonto(e.target.value)}
                              required
                            />
                            <label>Monto</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-floating form-floating-outline mb-6">
                          <input
                            className="form-control"
                            type="date"
                            id="html5-date-input"
                            value={fechaPago}
                            onChange={(e) => setFechaPago(e.target.value)}
                            required
                          />
                          <label htmlFor="html5-date-input">Fecha de pago</label>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-floating form-floating-outline mb-6">
                          <select
                            className="form-select"
                            id="exampleFormControlSelect1"
                            aria-label="Tipo de pago"
                            value={tipo}
                            onChange={(e) => setTipo(e.target.value)}
                            required
                          >
                            <option value="">Tipo de pago</option>
                            <option value="SPEI">SPEI</option>
                            <option value="STP">STP</option>
                            <option value="CIE">CIE</option>
                          </select>
                          <label htmlFor="exampleFormControlSelect1">Tipo</label>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-floating form-floating-outline mb-6">
                          <select
                            className="form-select"
                            id="exampleFormControlSelect1"
                            aria-label="Estatus"
                            value={estatus}
                            onChange={(e) => setEstatus(e.target.value)}
                            required
                          >
                            <option value="">Selecciona un estatus</option>
                            <option value="RECURRENCIA">RECURRENCIA</option>
                            <option value="INTENCION">INTENCION</option>
                            <option value="LIQUIDACION">LIQUIDACION</option>
                          </select>
                          <label htmlFor="exampleFormControlSelect1">Estatus</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <button type="submit" className="btn btn-primary waves-effect waves-light">
                        Enviar Pago
                      </button>
                    </div>

                    {/* {mensaje && <div className="alert alert-info mt-3">{mensaje}</div>} */}
                  </form>

                </div>
              </div>
              <div className="row">
                <div className="card mb-6">
                  <h5 className="card-header">3. Historial de pagos</h5>
                  <form className="card-body">
                    <div className="row g-6">
                      <div className="col-md-12">
                          <div className="table-responsive text-nowrap">
                            <table className="table">
                              <thead>
                                <tr className="text-nowrap">
                                  <th>AGENTE</th>
                                  <th>Actuación</th>
                                  <th>MONTO</th>
                                  <th>FECHA DE PAGO</th>
                                  <th>TIPO</th>
                                  <th>ESTATUS</th>
                                </tr>
                              </thead>
                              <tbody className="table-border-bottom-0">
                                {pagos.length > 0 ? (
                                  pagos.map((pago, index) => (
                                    <tr key={index}>
                                      <th scope="row" style={{textTransform:'lowercase'}}>{pago.AGENTE}</th>
                                      <td>{pago.PAGOS_ACTUACION ? new Date(pago.PAGOS_ACTUACION).toLocaleDateString('es-MX', { day: '2-digit',month: '2-digit',year: '2-digit'}): '-'}</td>
                                      <td>${parseFloat(pago.PAGOS_MONTO).toLocaleString('es-MX')}</td>
                                      <td>{new Date(pago.PAGOS_FECHA_PAGO).toLocaleDateString('es-MX')}</td>
                                      <td>{pago.PAGOS_TIPO}</td>
                                      <td>{pago.PAGOS_ESTATUS}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="6">No hay pagos disponibles</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function PanelUsuario() {
  return(
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <Header />
        <div className="layout-page">
          <Nav />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-12">
                    <h5 className="card-header">Usuario</h5>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gestiones