import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState(null); // Información del usuario
    const [loading, setLoading] = useState(true);   // Indicador de carga

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                // Verifica si ya tenemos datos del usuario almacenados en localStorage
                const storedUserData = localStorage.getItem('userData');
                
                if (storedUserData) {
                    // Si tenemos datos almacenados, los usamos directamente
                    setUserData(JSON.parse(storedUserData));
                    setLoading(false); // Marcamos que ya no estamos cargando
                } else {
                    // Si no hay datos, hacemos la solicitud al servidor
                    const res = await axios.get('https://system-cammart-production.up.railway.app/user-info', { withCredentials: true });

                    if (res.status === 200) {
                        setUserData(res.data); // Guardamos los datos en el estado
                        localStorage.setItem('userData', JSON.stringify(res.data)); // Almacenamos los datos en localStorage
                    } else {
                        console.error('Error al obtener los datos del usuario:', res.data.message);
                    }
                }
            } catch (error) {
                console.error('Error al obtener los datos del usuario:', error);
            } finally {
                setLoading(false); // Terminamos la carga
            }
        };

        fetchUserData(); // Ejecutamos la función al cargar el componente
    }, []);

    return (
        <UserContext.Provider value={{ userData, loading }}>
            {children}
        </UserContext.Provider>
    );
};
