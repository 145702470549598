import React, { useState, useEffect } from 'react';
import {Header, Nav, Footer} from '../widgets/Header';
import { Link } from 'react-router-dom';
import axios from 'axios';

export function Perfil() {
    const [perfil, setPerfil] = useState(null);
    const [gestiones, setGestiones] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const obtenerDatosPerfil = async () => {
            try {
                // Hacemos una solicitud para obtener los datos del usuario basados en el token
                const response = await axios.get('https://system-cammart-production.up.railway.app/datos-perfil', {
                    withCredentials: true // Asegura que se envíen las cookies con el token
                });

                // Asignamos los datos al estado
                setPerfil(response.data.usuario);
                setGestiones(response.data.gestiones);
                setLoading(false); // Ya se han cargado los datos
            } catch (error) {
                console.error('Error al obtener los datos del perfil:', error);
                setLoading(false);
            }
        };

        obtenerDatosPerfil();
    }, []);

    if (loading) return <div>Cargando...</div>; // Mostrar "Cargando" mientras se obtienen los datos

    if (!perfil) return <div>Error: No se pudieron cargar los datos del perfil</div>;


    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Header />
                <div className="layout-page">
                    <Nav />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                        <div className="row">
                            <div className="col-12">
                                <div className="card mb-6">
                                    <div className="user-profile-header-banner">
                                    <img src="../../assets/img/pages/profile-banner.png" alt="Banner image" className="rounded-top"/>
                                    </div>
                                    <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-5">
                                    <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                                        <img src="../../assets/img/avatars/1.jpeg" alt="user image" className="d-block h-auto ms-0 ms-sm-5 rounded-4 user-profile-img"/>
                                    </div>
                                    <div className="flex-grow-1 mt-4 mt-sm-12">
                                        <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-5 flex-md-row flex-column gap-6">
                                            <div className="user-profile-info">
                                                <h4 className="mb-2">Lic. {perfil.nombre_completo}</h4>
                                            </div>
                                        <a href="javascript:void(0)" className="btn btn-primary waves-effect waves-light">
                                            <i className="ri-user-follow-line ri-16px me-2"></i>Conectado
                                        </a>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-xl-4 col-lg-5 col-md-5">
                                <div className="card mb-6">
                                    <div className="card-body">
                                        <small className="card-text text-uppercase text-muted small">Sobre mí</small>
                                        <ul className="list-unstyled my-3 py-1">
                                            <li className="d-flex align-items-center mb-4">
                                                <i className="ri-user-3-line ri-24px"></i>
                                                <span className="fw-medium mx-2">Nombre completo:</span>
                                                <span>{perfil.nombre_completo}</span>
                                            </li>
                                            <li className="d-flex align-items-center mb-4">
                                                <i className="ri-check-line ri-24px"></i>
                                                <span className="fw-medium mx-2">Status:</span>
                                                <span>{perfil.estatus}</span>
                                            </li>
                                            <li className="d-flex align-items-center mb-4">
                                                <i className="ri-star-smile-line ri-24px"></i>
                                                <span className="fw-medium mx-2">Role:</span>
                                                <span>{perfil.tipo}</span>
                                            </li>
                                        </ul>
                                        <small className="card-text text-uppercase text-muted small">Contacto</small>
                                        <ul className="list-unstyled my-3 py-1">
                                            <li className="d-flex align-items-center mb-2">
                                                <i className="ri-mail-open-line ri-24px"></i>
                                                <span className="fw-medium mx-2">Email:</span>
                                                <span>{perfil.correo}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-8 col-lg-7 col-md-7">
                                <div className="card">
                                    <h5 className="card-header">Historico</h5>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr className="">
                                                    <th>#</th>
                                                    <th>Comentario</th>
                                                    <th>Estatus</th>
                                                    <th>Actuación</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {gestiones.map((gestion, index) => (
                                                    <tr key={index}>
                                                        <td><Link to={`/gestion/${gestion.GESTION_CLIENTE_UNICO}`}>{gestion.GESTION_CLIENTE_UNICO}</Link></td>
                                                        <td>{gestion.GESTION_COMENTARIO}</td>
                                                        <td>{gestion.GESTION_ESTATUS}</td>
                                                        <td>{new Date(gestion.GESTION_ACTUACION).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: '2-digit' })}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        </div>
                        <Footer />
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Perfil