import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { registerables } from 'chart.js';
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { getISOWeek, addWeeks, subWeeks, startOfISOWeek, endOfISOWeek, format } from 'date-fns';

Chart.register(...registerables);

export function PagosSemanalChart() {
    const [pagosSemanales, setPagosSemanales] = useState([]);
    const currentYear = 2024;  // Año actual
    const [currentPage, setCurrentPage] = useState(0);
    const weeksPerPage = 5;  // Mostramos 7 semanas por página

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://system-cammart-production.up.railway.app/pagos-semanales`);
                setPagosSemanales(response.data);
            } catch (error) {
                console.error('Error fetching pagos semanales:', error);
            }
        };

        fetchData();
    }, []);

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if ((currentPage + 1) * weeksPerPage < pagosSemanales.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const paginatedData = pagosSemanales.slice(
        currentPage * weeksPerPage,
        (currentPage + 1) * weeksPerPage
    );

    const data = {
        labels: paginatedData.map(item => `SEM ${item.semana}`),
        datasets: [
            {
                label: 'Pagos Semanales',
                data: paginatedData.map(item => item.total_monto),
                backgroundColor: '#c4943c80',
                borderColor: '#c4943c',
                borderWidth: 1,
                fill: true,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        animation: {
            duration: 2000, // Duración de la animación en ms
            easing: 'easeInOutQuad', // Efecto de animación
            loop: false, // Si quieres que la animación se repita en bucle
            animateScale: true, // Anima la escala de los ejes
            animateRotate: true, // Anima la rotación (útil en gráficos circulares)
        },
    };

    return (
        <div>
            <Line data={data} options={options} />
            <div className="pagination-buttons mt-3" style={{float:'right'}}>
                <button className='btn btn-outline-secondary waves-effect' style={{marginRight:'10px'}} onClick={handlePreviousPage} disabled={currentPage === 0}>
                    Anterior
                </button>
                <button className='btn btn-outline-secondary waves-effect' onClick={handleNextPage} disabled={(currentPage + 1) * weeksPerPage >= pagosSemanales.length}>
                    Siguiente
                </button>
            </div>
        </div>
    );
}

export function PagosPorSemana () {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [pagosSemanales, setPagosSemanales] = useState([]);
    const [weeksInYear] = useState(52); // Definimos las semanas en el año

    const currentWeek = getISOWeek(currentDate);

    useEffect(() => {
        // Obtener los pagos para la semana actual
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://system-cammart-production.up.railway.app/pagos-semana/${currentWeek}`);
                setPagosSemanales(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [currentWeek]);

    // Función para cambiar a la semana siguiente
    const handleNextWeek = () => {
        setCurrentDate(prevDate => addWeeks(prevDate, 1));
    };

    // Función para cambiar a la semana anterior
    const handlePreviousWeek = () => {
        setCurrentDate(prevDate => subWeeks(prevDate, 1));
    };

    // Obtener los 7 días de la semana (Lunes a Domingo)
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
        const currentDay = new Date(startOfISOWeek(currentDate));
        currentDay.setDate(currentDay.getDate() + i);
        weekDays.push(format(currentDay, 'yyyy-MM-dd'));
    }

    // Asegurarse de que haya datos para todos los días de la semana
    const pagosConDiasCompletos = weekDays.map(day => {
        const pagoDelDia = pagosSemanales.find(pago => pago.fecha_pago === day);
        return {
            fecha_pago: day,
            total_monto: pagoDelDia ? parseFloat(pagoDelDia.total_monto) : 0
        };
    });

    const data = {
        labels: pagosConDiasCompletos.map(item => item.fecha_pago), // Etiquetas con las fechas de la semana
        datasets: [
            {
                label: 'Monto Diario',
                data: pagosConDiasCompletos.map(item => item.total_monto),
                backgroundColor: '#041c3473',
                borderColor: '#041c34',
                borderWidth: 1,
                fill: true,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        animation: {
            duration: 2000, // Animación progresiva de 2 segundos
            easing: 'easeInOutQuad',
            animateScale: true,
        },
    };

    return (
            <div>
                <div>
                    <h5 className="card-title mb-0">
                        Registros de la Semana {currentWeek}
                    </h5>
                </div>
                <div className="card-body">
                    <Bar data={data} options={options} />
                </div>
                <div className="card-footer">
                    <div className="d-flex justify-content-between">
                        <button className="btn btn-outline-secondary waves-effect" onClick={handlePreviousWeek} disabled={currentWeek === 1}>
                            Semana Anterior
                        </button>
                        <button className="btn btn-outline-secondary waves-effect" onClick={handleNextWeek} disabled={currentWeek === weeksInYear}>
                            Siguiente Semana
                        </button>
                    </div>
                </div>
            </div>
    );
}

export function Charts() {
    return (
        <div>Charts</div>
    )
}

export default Charts