import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Header, Nav, Footer} from '../widgets/Header'
import axios from 'axios';
import Swal from 'sweetalert2';

export function Reportes() {
    return (
        <div>Reportes</div>
    )
}

export function Exportar() {
    const [gestiones, setGestiones] = useState([]);
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [currentPage, setCurrentPage] = useState(1); // Para la paginación
    const [loadingCarteo, setLoadingCarteo] = useState(false);
    const [loadingGestiones, setLoadingGestiones] = useState(false);
    const itemsPerPage = 15; // Elementos por página


    // Cargar las gestiones al montar el componente
    useEffect(() => {
        axios.get('https://system-cammart-production.up.railway.app/gestiones')
            .then(res => {
                setGestiones(res.data);  // Guardamos los datos en el estado
            })
            .catch(error => {
                console.error('Error al obtener gestiones:', error);
            });
    }, []);

    // Calcular los elementos a mostrar según la página
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = gestiones.slice(indexOfFirstItem, indexOfLastItem);

    // Calcular el número total de páginas
    const totalPages = Math.ceil(gestiones.length / itemsPerPage);

    // Funciones para cambiar de página
    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const goToFirstPage = () => {
        setCurrentPage(1);
    };

    const goToLastPage = () => {
        setCurrentPage(totalPages);
    };

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    // Función para exportar base carteo
    const exportarBaseCarteo = async () => {
        setLoadingCarteo(true); // Cambiar el estado a "Cargando..."
        try {
            const response = await axios.get('https://system-cammart-production.up.railway.app/exportar-base-carteo', {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'base_carteo.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error al exportar la base de carteo:', error);
        } finally {
            setLoadingCarteo(false); // Restaurar el botón
        }
    };

    // Función para exportar gestiones con rango de fechas
    const exportarGestiones = async () => {
        // Validar si las fechas están vacías
        if (!fechaInicio || !fechaFin) {
            Swal.fire({
                icon: 'error',
                title: 'Fechas faltantes',
                text: 'Por favor, ingresa ambas fechas para exportar los datos.'
            });
            return;
        }

        // Validar que la fecha de inicio no sea mayor que la fecha de fin
        if (new Date(fechaInicio) > new Date(fechaFin)) {
            Swal.fire({
                icon: 'error',
                title: 'Rango de fechas inválido',
                text: 'La fecha de inicio no puede ser mayor que la fecha de fin.'
            });
            return;
        }
        setLoadingGestiones(true); // Cambiar el estado a "Cargando..."
        try {
            const response = await axios.post('https://system-cammart-production.up.railway.app/exportar-gestiones', {
                fechaInicio,
                fechaFin
            }, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'gestiones.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error al exportar gestiones:', error);
        } finally {
            setLoadingGestiones(false); // Restaurar el botón
        }
    };

    return(
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Header />
                <div className="layout-page">
                <Nav />
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <div className="row">
                            {/* <div className="row g-6 mb-6">
                                <div className="col-sm-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="me-1">
                                        <p className="text-heading mb-1">Session</p>
                                        <div className="d-flex align-items-center">
                                            <h4 className="mb-1 me-2">21,459</h4>
                                            <p className="text-success mb-1">(+29%)</p>
                                        </div>
                                        <small className="mb-0">Total Users</small>
                                        </div>
                                        <div className="avatar">
                                        <div className="avatar-initial bg-label-primary rounded-3">
                                            <div className="ri-group-line ri-26px"></div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="col-sm-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="me-1">
                                        <p className="text-heading mb-1">Paid Users</p>
                                        <div className="d-flex align-items-center">
                                            <h4 className="mb-1 me-1">4,567</h4>
                                            <p className="text-success mb-1">(+18%)</p>
                                        </div>
                                        <small className="mb-0">Last week analytics</small>
                                        </div>
                                        <div className="avatar">
                                        <div className="avatar-initial bg-label-danger rounded-3">
                                            <div className="ri-user-add-line ri-26px"></div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="col-sm-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="me-1">
                                        <p className="text-heading mb-1">Active Users</p>
                                        <div className="d-flex align-items-center">
                                            <h4 className="mb-1 me-1">19,860</h4>
                                            <p className="text-danger mb-1">(-14%)</p>
                                        </div>
                                        <small className="mb-0">Last week analytics</small>
                                        </div>
                                        <div className="avatar">
                                        <div className="avatar-initial bg-label-success rounded-3">
                                            <div className="ri-user-follow-line ri-26px"></div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="col-sm-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="me-1">
                                        <p className="text-heading mb-1">Pending Users</p>
                                        <div className="d-flex align-items-center">
                                            <h4 className="mb-1 me-1">237</h4>
                                            <p className="text-success mb-1">(+42%)</p>
                                        </div>
                                        <small className="mb-0">Last week analytics</small>
                                        </div>
                                        <div className="avatar">
                                        <div className="avatar-initial bg-label-warning rounded-3">
                                            <div className="ri-user-search-line ri-26px"></div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div> */}

                            <div className="card">
                                <div className="card-header border-bottom d-flex justify-content-between align-items-center">
                                    <h5 className="card-title mb-0">Exportación de Reporte</h5>
                                    {/* Botón de exportar base carteo, alineado a la derecha */}
                                    <button className="btn btn-primary" onClick={exportarBaseCarteo} disabled={loadingCarteo}>
                                        {loadingCarteo ? 'Cargando...' : 'Exportar Base Carteo'}
                                    </button>
                                </div>
                                <div className="card-body">
                                    {/* Fila de input de fechas y botón de exportar */}
                                    <div className="d-flex align-items-center mt-4">
                                        <input
                                            type="date"
                                            className="form-control me-2"
                                            value={fechaInicio}
                                            onChange={(e) => setFechaInicio(e.target.value)}
                                        />
                                        <input
                                            type="date"
                                            className="form-control me-2"
                                            value={fechaFin}
                                            onChange={(e) => setFechaFin(e.target.value)}
                                        />
                                        <button className="btn btn-primary" onClick={exportarGestiones} disabled={loadingGestiones}>
                                            {loadingGestiones ? 'Cargando...' : 'Exportar Gestiones'}
                                        </button>
                                    </div>
                                </div>
                                <div className="table-responsive text-nowrap">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Cliente Único</th>
                                                <th>Agente</th>
                                                <th>Comentario</th>
                                                <th>Estatus</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0">
                                            {currentItems.length > 0 ? (
                                                currentItems.map((gestion, index) => (
                                                    <tr key={index}>
                                                        <td><Link to={`/gestion/${gestion.GESTION_CLIENTE_UNICO}`}>{gestion.GESTION_CLIENTE_UNICO}</Link></td>
                                                        <td>
                                                            <ul className="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
                                                                <li className="avatar avatar-xs pull-up" data-bs-toggle="tooltip" aria-label="Agente">
                                                                    <img src="../../assets/img/avatars/5.png" alt="Avatar" className="rounded-circle" /> {gestion.USER_USUARIO}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                        <td>{gestion.GESTION_COMENTARIO}</td>
                                                        <td>{gestion.GESTION_ESTATUS}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="4">No hay gestiones disponibles</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                    {/* Paginador */}
                                    <div className="d-flex justify-content-center">
                                        <nav aria-label="Page navigation">
                                            <ul className="pagination pagination-outline-primary">
                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <a className="page-link waves-effect" onClick={goToFirstPage}><i className="tf-icon ri-skip-back-mini-line ri-20px"></i></a>
                                                </li>
                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <a className="page-link waves-effect" onClick={goToPrevPage}><i className="tf-icon ri-arrow-left-s-line ri-20px"></i></a>
                                                </li>
                                                {[...Array(totalPages).keys()].map(page => (
                                                    <li className={`page-item ${currentPage === page + 1 ? 'active' : ''}`} key={page}>
                                                        <a className="page-link waves-effect" onClick={() => goToPage(page + 1)}>{page + 1}</a>
                                                    </li>
                                                ))}
                                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                    <a className="page-link waves-effect" onClick={goToNextPage}><i className="tf-icon ri-arrow-right-s-line ri-20px"></i></a>
                                                </li>
                                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                    <a className="page-link waves-effect" onClick={goToLastPage}><i className="tf-icon ri-skip-forward-mini-line ri-20px"></i></a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <div className="content-backdrop fade"></div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Reportes