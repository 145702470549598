import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import axios from 'axios';

export function Login() {
    const { setUserId } = useContext(UserContext);
    const [usuario, setUsuario] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate(); // Para redirigir después de un login exitoso

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevenir el comportamiento por defecto del form

        try {
            // Realizar solicitud de inicio de sesión al backend
            const response = await axios.post('https://system-cammart-production.up.railway.app/login', {
                usuario,
                password
            }, {
                withCredentials: true // Permite que las cookies sean enviadas desde el backend
            });

            // Si el login es exitoso, redirigir a la página principal "/"
            if (response.data.message === 'Inicio de sesión exitoso') {
                navigate('/'); // Redirigir al Home o cualquier ruta protegida
            } else {
                // Manejar error de inicio de sesión
                setErrorMessage(response.data.message || 'Error al iniciar sesión');
            }
        } catch (error) {
            // Manejar errores de red o del servidor
            setErrorMessage('Error en la conexión con el servidor');
        }
    };

    return (
    <div>
        <div className="position-relative">
            <div className="authentication-wrapper authentication-basic container-p-y p-4 p-sm-0">
                <div className="authentication-inner py-6">
                <div className="card p-md-7 p-1">
                    <div className="app-brand justify-content-center mt-5">
                        <span className="app-brand-text demo text-heading fw-semibold">CAMMART</span>
                    </div>

                    <div className="card-body mt-1">
                    <h4 className="mb-1">¡Bienvenido! 👋</h4>
                    <p className="mb-5">Por favor, inicia sesión en tu cuenta.</p>

                    <form onSubmit={handleSubmit} className="mb-5">
                        <div className="form-floating form-floating-outline mb-5">
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email-username"
                            placeholder="Ingresa tu Email o tu nombre de usuario"
                            value={usuario}
                            onChange={(e) => setUsuario(e.target.value)}
                            autoFocus />
                        <label htmlFor="email">Email o Usuario</label>
                        </div>
                        <div className="mb-5">
                            <div className="form-password-toggle">
                                <div className="input-group input-group-merge">
                                    <div className="form-floating form-floating-outline">
                                        <input
                                            type={passwordVisible ? 'text' : 'password'} // Cambiar entre 'text' y 'password'
                                            id="password"
                                            className="form-control"
                                            name="password"
                                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                            aria-describedby="Ingresa tu contraseña"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                        <label htmlFor="password">Password</label>
                                    </div>
                                    <span
                                        className="input-group-text cursor-pointer"
                                        onClick={togglePasswordVisibility}
                                    >
                                        <i className={passwordVisible ? 'ri-eye-line' : 'ri-eye-off-line'}></i>
                                        {/* Cambiar el ícono basado en el estado de visibilidad */}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* Mostrar error si existe */}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

                        {/* <div className="mb-5 d-flex justify-content-between mt-5">
                            <a href="auth-forgot-password-basic.html" className="float-end mb-1 mt-2">
                                <span>¿Olvidaste tu contraseña?</span>
                            </a>
                        </div> */}
                        <div className="mb-5">
                        <button className="btn btn-primary d-grid w-100" type="submit">Ingresar</button>
                        </div>
                    </form>
                    </div>
                </div>
                <img
                    alt="mask"
                    src="../../assets/img/illustrations/auth-basic-login-mask-light.png"
                    className="authentication-image d-none d-lg-block"
                    data-app-light-img="illustrations/auth-basic-login-mask-light.png"
                    data-app-dark-img="illustrations/auth-basic-login-mask-dark.png" />
                </div>
            </div>
        </div>
    </div>
    )
}

export function Register() {
    return (
        <div>
            <div className="position-relative">
                <div className="authentication-wrapper authentication-basic container-p-y p-4 p-sm-0">
                    <div className="authentication-inner py-6">
                    <div className="card p-md-7 p-1">
                        <div className="app-brand justify-content-center mt-5">
                        <a href="index.html" className="app-brand-link gap-2">
                            <span className="app-brand-text demo text-heading fw-semibold">CAMMART</span>
                        </a>
                        </div>
                        <div className="card-body mt-1">
                        <h4 className="mb-1">Registro 🚀</h4>
                        <p className="mb-5">Por favor, ingresa tu información.</p>

                        <form id="formAuthentication" className="mb-5" action="index.html" method="GET">
                            <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                name="username"
                                placeholder="Ingresa un nombre de usuario"
                                autoFocus />
                            <label for="username">Usuario</label>
                            </div>
                            <div className="form-floating form-floating-outline mb-5">
                            <input type="text" className="form-control" id="email" name="email" placeholder="Ingresa tu correo electronico" />
                            <label for="email">Email</label>
                            </div>
                            <div className="mb-5 form-password-toggle">
                            <div className="input-group input-group-merge">
                                <div className="form-floating form-floating-outline">
                                <input
                                    type="password"
                                    id="password"
                                    className="form-control"
                                    name="password"
                                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                    aria-describedby="password" />
                                <label for="password">Password</label>
                                </div>
                                <span className="input-group-text cursor-pointer"><i className="ri-eye-off-line"></i></span>
                            </div>
                            </div>
                            <button className="btn btn-primary d-grid w-100">Registrar</button>
                        </form>

                        <p className="text-center">
                            <span>¿Ya tienes una cuenta? </span>
                            <a href="/login">
                            <span> Ingresa aquí</span>
                            </a>
                        </p>
                        </div>
                    </div>
                    <img
                        alt="mask"
                        src="../../assets/img/illustrations/auth-basic-register-mask-light.png"
                        className="authentication-image d-none d-lg-block"
                        data-app-light-img="illustrations/auth-basic-register-mask-light.png"
                        data-app-dark-img="illustrations/auth-basic-register-mask-dark.png" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login