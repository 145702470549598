import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import axios from 'axios';

export function NotFound() {
    return (
        <div className="misc-wrapper">
            <h1 className="mb-2 mx-2" style={{fontsize: '6rem', lineheight: '6rem'}}>404</h1>
            <h4 className="mb-2">Página no encontrada ⚠️</h4>
            <p className="mb-6 mx-2">No hemos podido encontrar la página que busca</p>
            <div className="d-flex justify-content-center mt-9">
                <img src="../../assets/img/illustrations/misc-error-object.png" alt="misc-error" className="img-fluid misc-object d-none d-lg-inline-block" width="160" />
                <img src="../../assets/img/illustrations/misc-bg-light.png" alt="misc-error" className="misc-bg d-none d-lg-inline-block" data-app-light-img="illustrations/misc-bg-light.png" data-app-dark-img="illustrations/misc-bg-dark.png" />
                <div className="d-flex flex-column align-items-center">
                <img src="../../assets/img/illustrations/misc-error-illustration.png" alt="misc-error" className="img-fluid z-1" width="190" />
                <div>
                    <a href="/" className="btn btn-primary text-center my-10 waves-effect waves-light">Ir al inicio</a>
                </div>
                </div>
            </div>
        </div>
    )
}

export function Header() {
    //Menu vertical
    const toggleMenu = (e) => {
        e.preventDefault();
        const body = document.querySelector('body');
        if (body.classList.contains('layout-menu-collapsed')) {
            body.classList.toggle('layout-menu-collapsed');
        } else {
            body.classList.add('layout-menu-collapsed');
        }
    };
        const handleMouseEnter = () => {
        const body = document.querySelector('body');
        if (body.classList.contains('layout-menu-collapsed')) {
            body.classList.add('layout-menu-hover');
        }
    };
        const handleMouseLeave = () => {
        const body = document.querySelector('body');
        body.classList.remove('layout-menu-hover');
    };

    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <div>
            <aside onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="layout-menu menu-vertical menu bg-menu-theme">
                <div className="app-brand demo">
                    <a href="/" className="app-brand-link">
                    <span className="app-brand-text demo menu-text fw-semibold ms-2">CAMMART</span>
                    </a>
                    <a onClick={toggleMenu} className="layout-menu-toggle menu-link text-large ms-auto">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                            d="M8.47365 11.7183C8.11707 12.0749 8.11707 12.6531 8.47365 13.0097L12.071 16.607C12.4615 16.9975 12.4615 17.6305 12.071 18.021C11.6805 18.4115 11.0475 18.4115 10.657 18.021L5.83009 13.1941C5.37164 12.7356 5.37164 11.9924 5.83009 11.5339L10.657 6.707C11.0475 6.31653 11.6805 6.31653 12.071 6.707C12.4615 7.09747 12.4615 7.73053 12.071 8.121L8.47365 11.7183Z"
                            fillOpacity="0.9" />
                            <path
                            d="M14.3584 11.8336C14.0654 12.1266 14.0654 12.6014 14.3584 12.8944L18.071 16.607C18.4615 16.9975 18.4615 17.6305 18.071 18.021C17.6805 18.4115 17.0475 18.4115 16.657 18.021L11.6819 13.0459C11.3053 12.6693 11.3053 12.0587 11.6819 11.6821L16.657 6.707C17.0475 6.31653 17.6805 6.31653 18.071 6.707C18.4615 7.09747 18.4615 7.73053 18.071 8.121L14.3584 11.8336Z"
                            fillOpacity="0.4" />
                        </svg>
                    </a>
                </div>
                <div className="menu-inner-shadow"></div>
                <ul className="menu-inner py-1">
                    <li className={`menu-item ${isOpen ? 'open' : ''}`}>
                    <a className="menu-link menu-toggle" onClick={handleToggle}>
                        <i className="menu-icon tf-icons ri-home-smile-line"></i>
                        <div data-i18n="Dashboard">Dashboard</div>
                        {/* <div className="badge bg-danger rounded-pill ms-auto">5</div> */}
                    </a>
                    <ul className="menu-sub">
                        <li className={`menu-item ${isActive('/')}`}>
                            <Link to="/" className="menu-link">
                                <div data-i18n="CRM">General</div>
                            </Link>
                        </li>
                    </ul>
                    </li>
                    <li className="menu-header mt-5">
                        <span className="menu-header-text" >Gestión</span>
                    </li>
                    <li className={`menu-item ${isActive('/panel/usuario')}`}>
                        <Link to="/panel/usuario" className="menu-link">
                            <i className="menu-icon tf-icons ri-window-2-line"></i>
                            <div >Panel | usuario</div>
                        </Link>
                    </li>
                    <li className={`menu-item ${isActive('/gestion')}`}>
                        <Link to="/gestion" className="menu-link">
                            <i className="menu-icon tf-icons ri-customer-service-2-line"></i>
                            <div >Gestiones</div>
                        </Link>
                    </li>
                    <li className={`menu-item ${isActive('/pagos')}`}>
                        <Link to="/pagos" className="menu-link">
                            <i className="menu-icon tf-icons ri-currency-line"></i>
                            <div >Pagos</div>
                        </Link>
                    </li>
                    <li className="menu-header mt-5">
                        <span className="menu-header-text" >Reportes</span>
                    </li>
                    <li className={`menu-item ${isActive('/reportes/exportar')}`}>
                        <Link to="/reportes/exportar" className="menu-link">
                            <i className="menu-icon tf-icons ri-file-download-line"></i>
                            <div >Exportar Información</div>
                        </Link>
                    </li>
                    <li className="menu-header mt-5">
                        <span className="menu-header-text" >Modulos</span>
                    </li>
                    <li className={`menu-item ${isActive('/whatsapp')}`}>
                        <Link to="/whatsapp" className="menu-link">
                            <i className="menu-icon tf-icons ri-whatsapp-line"></i>
                            <div >Whatsapp</div>
                        </Link>
                    </li>
                    <li className="menu-header mt-5">
                        <span className="menu-header-text" >Configuraciones</span>
                    </li>
                    <li className={`menu-item ${isActive('/configuracion/importar')}`}>
                        <Link to="/configuracion/importar" className="menu-link">
                            <i className="menu-icon tf-icons ri-import-line"></i>
                            <div >Importar datos</div>
                        </Link>
                    </li>
                    <li className={`menu-item ${isActive('/soporte')}`}>
                        <Link to="/soporte" className="menu-link">
                            <i className="menu-icon tf-icons ri-file-code-line"></i>
                            <div >Soporte</div>
                        </Link>
                    </li>
                    <li className={`menu-item ${isActive('/configuracion/panel')}`}>
                        <Link to="/configuracion/panel" className="menu-link">
                            <i className="menu-icon tf-icons ri-tools-line"></i>
                            <div >Más configuraciones</div>
                        </Link>
                    </li>
                </ul>
            </aside>
        </div>
    )
}

export function Nav() {

    const [userInfo, setUserInfo] = useState({ usuario: '', tipo: '' });
    const [clienteUnico, setClienteUnico] = useState('');
    const navigate = useNavigate();

    const handleSearch = (e) => {
        e.preventDefault(); // Evitar que el formulario recargue la página
        if (clienteUnico) {
            // Redirigir a la ruta con el CLIENTE_UNICO
            navigate(`/gestion/${clienteUnico}`);
        }
    };

    // Obtener la información del usuario desde el backend para datos de sesión
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await axios.get('https://system-cammart-production.up.railway.app/user-info', { withCredentials: true });
                if (response.data) {
                    setUserInfo({ usuario: response.data.usuario, tipo: response.data.tipo });
                }
            } catch (error) {
                console.error('Error obteniendo la información del usuario', error);
            }
        };

        fetchUserInfo();
    }, []);

    const handleLogout = async () => {
        try {
            await axios.post('https://system-cammart-production.up.railway.app/logout', {}, { withCredentials: true });
            window.location.href = '/login'; // Redirigir al login después de cerrar sesión
        } catch (error) {
            console.error('Error al cerrar sesión', error);
        }
    };
    
    return(
        <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0 d-xl-none">
            <a className="nav-item nav-link px-0 me-xl-6">
                <i className="ri-menu-fill ri-22px"></i>
            </a>
            </div>
            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <form className="d-flex" onSubmit={handleSearch}>
                    <div className="input-group input-group-sm">
                        <span className="input-group-text">
                            <i className="tf-icons ri-search-line"></i>
                        </span>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Buscar..." 
                            value={clienteUnico}
                            onChange={(e) => setClienteUnico(e.target.value)} // Captura del valor
                        />
                    </div>
                </form>
            <ul className="navbar-nav flex-row align-items-center ms-auto">
                {/* <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-4 me-xl-1">
                <a
                    className="nav-link btn btn-text-secondary rounded-pill btn-icon dropdown-toggle hide-arrow"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false">
                    <i className="ri-notification-2-line ri-22px"></i>
                    <span className="position-absolute top-0 start-50 translate-middle-y badge badge-dot bg-danger mt-2 border"></span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end py-0">
                    <li className="dropdown-menu-header border-bottom py-50">
                    <div className="dropdown-header d-flex align-items-center py-2">
                        <h6 className="mb-0 me-auto">Notificación</h6>
                        <div className="d-flex align-items-center">
                        <span className="badge rounded-pill bg-label-primary fs-xsmall me-2">8 Nuevas</span>
                        <a
                            className="btn btn-text-secondary rounded-pill btn-icon dropdown-notifications-all"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Mark all as read"
                            >
                            <i className="ri-mail-open-line text-heading ri-20px"></i></a>
                        </div>
                    </div>
                    </li>
                    <li className="dropdown-notifications-list scrollable-container">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item list-group-item-action dropdown-notifications-item">
                        <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                            <div className="avatar">
                                <img src="../../assets/img/avatars/1.jpeg"  className="rounded-circle" />
                            </div>
                            </div>
                            <div className="flex-grow-1">
                            <h6 className="small mb-1">Congratulation Lettie 🎉</h6>
                            <small className="mb-1 d-block text-body">Won the monthly best seller gold badge</small>
                            <small className="text-muted">1h ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                            <a className="dropdown-notifications-read"
                                ><span className="badge badge-dot"></span></a>
                            <a className="dropdown-notifications-archive">
                                <span className="ri-close-line ri-20px"></span>
                            </a>
                            </div>
                        </div>
                        </li>
                    </ul>
                    </li>
                    <li className="border-top">
                    <div className="d-grid p-4">
                        <a className="btn btn-primary btn-sm d-flex">
                        <small className="align-middle">Ver todas las notificaciones</small>
                        </a>
                    </div>
                    </li>
                </ul>
                </li> */}
                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <a className="nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <div className="avatar avatar-online">
                    <img src="../../assets/img/avatars/1.jpeg"  className="rounded-circle" />
                    </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                        <a className="dropdown-item" href="">
                            <div className="d-flex">
                                <div className="flex-shrink-0 me-2">
                                    <div className="avatar avatar-online">
                                        <img src="../../assets/img/avatars/1.jpeg" className="rounded-circle" alt="Avatar" />
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <span className="fw-medium d-block small">{userInfo.usuario}</span>
                                    <small className="text-muted">{userInfo.tipo}</small>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <div className="dropdown-divider"></div>
                    </li>
                    <li>
                        <a className="dropdown-item" href="/perfil">
                            <i className="ri-user-3-line ri-22px me-3"></i>
                            <span className="align-middle">Mi Perfil</span>
                        </a>
                    </li>
                    <li>
                        <a className="dropdown-item" href="">
                            <i className="ri-settings-4-line ri-22px me-3"></i>
                            <span className="align-middle">Configuración</span>
                        </a>
                    </li>
                    <li>
                        <div className="d-grid px-4 pt-2 pb-1">
                            <button className="btn btn-sm btn-danger d-flex" onClick={handleLogout}>
                                <small className="align-middle">Cerrar sesión</small>
                                <i className="ri-logout-box-r-line ms-2 ri-16px"></i>
                            </button>
                        </div>
                    </li>
                </ul>
                </li>
            </ul>
            </div>
            <div className="navbar-search-wrapper search-input-wrapper d-none">
            <input
                type="text"
                className="form-control search-input container-xxl border-0"
                placeholder="Buscar..."
                aria-label="Buscar..." />
            <i className="ri-close-fill search-toggler cursor-pointer"></i>
            </div>
        </nav>
    )
}

export function Footer() {
    return (
        <div>
            <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl">
                    <div className="footer-container d-flex align-items-center justify-content-between py-4 flex-md-row flex-column">
                        <div className="text-body mb-2 mb-md-0">
                            © 2024 , Desarrollado por
                            <a href="https://dm-series.com" target="_blank" className="footer-link" style={{paddingLeft:'5px'}}> DMS</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}


export default Header